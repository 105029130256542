import { useContext } from 'react';

import { ToastDispatchContext } from '@/components/providers';

export const useToastDispatch = () => {
  const context = useContext(ToastDispatchContext);
  if (!context)
    throw Error('useToastDispatch must be used within ToastProvider');
  return context;
};
