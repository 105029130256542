import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono ArrowRight
 */
export const ArrowRight = ({
  className,
  width = 24,
  height = 15,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    viewBox="0 0 24 15"
    fill="none"
  >
    <path
      d="M23.5037 7.76337C18.5053 7.76337 14.4609 4.52867 14.4609 0.539062"
      stroke={color}
      strokeMiterlimit="10"
    />
    <path
      d="M23.5037 7.76953C18.5053 7.76953 14.4609 11.0044 14.4609 15"
      stroke={color}
      strokeMiterlimit="10"
    />
    <path d="M22.8964 7.76949L0 7.76949" stroke={color} strokeMiterlimit="10" />
  </svg>
);
