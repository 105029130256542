import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono ListCheck
 */
export const ListCheck = ({
  className,
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      d="M10.667 5.335H28V8H10.667V5.335ZM4 4.668h4v4H4v-4Zm0 9.333h4v4H4v-4Zm0 9.334h4v4H4v-4Zm6.667-8.667H28v2.667H10.667v-2.667Zm0 9.333H28v2.667H10.667v-2.667Z"
      fill={color}
    />
  </svg>
);
