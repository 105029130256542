import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono Box
 */
export const Box = ({
  className,
  width = 19,
  height = 20,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 19 20"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.504 11.406a.572.572 0 0 0-.568.576v2.302c0 .318.254.576.568.576h3.788a.572.572 0 0 0 .568-.576v-2.302a.572.572 0 0 0-.568-.575H4.504Zm.568 2.303v-1.151h2.652v1.15H5.072Z"
      fill={color}
    />
    <path
      d="M11.512 11.982c0-.318.255-.575.568-.575h2.652c.314 0 .568.257.568.575a.572.572 0 0 1-.568.576H12.08a.572.572 0 0 1-.568-.576ZM12.08 13.709a.572.572 0 0 0-.568.575c0 .318.255.576.568.576h2.652a.572.572 0 0 0 .568-.576.572.572 0 0 0-.568-.575H12.08Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.367 2.083a.68.68 0 0 0-.57.313L.903 5.33a.697.697 0 0 0-.111.378v11.517c0 .381.305.69.681.69h16.053c.377 0 .682-.309.682-.69V5.709a.698.698 0 0 0-.09-.343L16.46 2.43a.68.68 0 0 0-.592-.348h-12.5Zm-.434 2.935 1.003-1.554H15.3l.878 1.554H2.933ZM2.375 6.4h14.25v9.933H2.375V6.4Z"
      fill={color}
    />
  </svg>
);
