import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Mic
 */
export const Mic = ({
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
  ...props
}: IconType): JSX.Element => (
  <svg width={width} height={height} {...props} fill="none" viewBox="0 0 24 24">
    <path
      fill={color}
      d="M12 3a3 3 0 0 0-3 3v4a3 3 0 0 0 6 0V6a3 3 0 0 0-3-3Zm0-2a5 5 0 0 1 5 5v4a5 5 0 1 1-10 0V6a5 5 0 0 1 5-5ZM3.055 11H5.07a7.002 7.002 0 0 0 13.858 0h2.016A9.004 9.004 0 0 1 13 18.945V23h-2v-4.055A9.004 9.004 0 0 1 3.055 11Z"
    />
  </svg>
);
