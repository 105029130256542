/**
 * Icono SymbolCart
 */
export const SymbolCart = (): JSX.Element => (
  <svg fill="none" viewBox="0 0 30 30" className="hidden">
    <symbol id="animate-cart-icon" viewBox="0 0 30 30">
      <path
        d="M14.5 5c1.67 0 3.273.665 4.455 1.85a6.324 6.324 0 0 1 1.845 4.466v1.052H25v2.106h-1.225l-.795 9.56a1.054 1.054 0 0 1-1.046.966H7.066a1.048 1.048 0 0 1-1.046-.965l-.796-9.561H4v-2.106h4.2v-1.052c0-1.675.664-3.282 1.845-4.466A6.292 6.292 0 0 1 14.5 5Zm7.167 9.474H7.332l.7 8.42h12.935l.7-8.42Zm-6.117 2.105v4.21h-2.1v-4.21h2.1Zm-4.2 0v4.21h-2.1v-4.21h2.1Zm8.4 0v4.21h-2.1v-4.21h2.1ZM14.5 7.105a4.195 4.195 0 0 0-2.895 1.16 4.216 4.216 0 0 0-1.3 2.84l-.005.21v1.053h8.4v-1.052c0-1.08-.414-2.12-1.157-2.902A4.196 4.196 0 0 0 14.71 7.11l-.21-.006Z"
        fill="currentColor"
      />
    </symbol>

    <symbol id="animate-cart-icon-with-hole" viewBox="0 0 30 30">
      <path
        d="M11.35 20.8h-2.1v-4.21h2.1v4.21Zm4.2-4.21h-2.1v4.21h2.1v-4.21Zm4.2 0h-2.1v4.21h2.1v-4.21Zm1.88-1.64-.66 7.95H8.03l-.7-8.42h12.45a7.422 7.422 0 0 1-2.95-2.11H10.3v-1.26a4.2 4.2 0 0 1 1.31-2.84c.78-.74 1.82-1.16 2.89-1.16h.21c.1.01.2.04.31.05.03-.72.17-1.42.4-2.08-.3-.04-.61-.08-.92-.08-1.67 0-3.27.67-4.45 1.85a6.328 6.328 0 0 0-1.85 4.47v1.05H4v2.11h1.22l.8 9.56c.02.26.14.51.34.69.19.18.45.28.71.28h14.87c.26 0 .52-.1.71-.28.19-.18.31-.42.34-.69l.76-9.15c-.4.07-.82.11-1.24.11-.3 0-.59-.02-.87-.06l-.01.01Z"
        fill="currentColor"
      />
    </symbol>

    <symbol id="animate-cart-active" viewBox="0 0 30 30">
      <path
        d="M22.5 0C18.4 0 15 3.4 15 7.5s3.4 7.5 7.5 7.5S30 11.6 30 7.5 26.6 0 22.5 0Zm-1.3 11.2-4-4 .9-.9 3.1 3.1L27 3.7l.9.9-6.7 6.6Z"
        fill="currentColor"
      />
    </symbol>

    <symbol id="animate-cart-loading" viewBox="0 0 30 30">
      <path
        d="M22.5 0C18.4 0 15 3.4 15 7.5s3.4 7.5 7.5 7.5S30 11.6 30 7.5 26.6 0 22.5 0Zm4.3 11.1c-.8 1-2 1.7-3.3 1.9-1.3.3-2.6.1-3.7-.6-1.1-.6-2-1.6-2.5-2.8-.5-1.2-.6-2.5-.2-3.7.4-1.2 1.2-2.3 2.3-3 1.1-.7 2.4-1 3.7-.9 1.3.1 2.5.7 3.4 1.6l-.9.9c-.7-.8-1.7-1.3-2.7-1.4-1-.1-2 .2-2.9.7-.8.6-1.4 1.5-1.7 2.4-.3.9-.2 2 .1 2.9.4.9 1.1 1.7 2 2.2.9.5 1.9.6 2.9.4 1-.2 1.9-.7 2.5-1.5.6-.8 1-1.8 1-2.8H28c.1 1.4-.3 2.7-1.2 3.7Z"
        fill="currentColor"
        className="animate-spin [transform-box:_fill-box] [transform-origin:_center]"
      />
    </symbol>

    <symbol id="animate-cart-error" viewBox="0 0 30 30">
      <path
        d="M22.5 0C18.4 0 15 3.4 15 7.5s3.4 7.5 7.5 7.5S30 11.6 30 7.5 26.6 0 22.5 0Zm.8 11.6h-1.6V10h1.6v1.6Zm0-3.2h-1.6V3.5h1.6v4.9Z"
        fill="currentColor"
      />
    </symbol>
  </svg>
);
