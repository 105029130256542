import { useState } from 'react';
import dynamic from 'next/dynamic';
import { oneLine } from 'common-tags';

import * as Icons from '@/icons';
import { ChevronRight, Copy, Reloj } from '@/icons';
import { NextLink } from '@/atoms';
import { Button } from '@/molecules';

import {
  useCart,
  useCartDispatch,
  useEmployee,
  useEmployeeSnackbar,
} from '@/lib/hooks';
import { removeBaseURL } from '@/lib/utils';

import type { ButtonProps } from '@/molecules/button';
import type { TypeSnackbarProps } from './types';

const Countdown = dynamic(() => import('@/organisms/countdown'), {
  ssr: false,
});

const ApplyCoupon = ({
  message,
  coupon,
}: {
  message: string;
  coupon: string;
}) => {
  const [applyingCoupon, setApplyingCoupon] = useState(false);
  const { applyCoupon } = useCartDispatch();
  const { cart, loading: cartLoading } = useCart();

  if (cartLoading) {
    return (
      <p className="u-body u-body--xs whitespace-nowrap lg:u-body--s">
        Cargando...
      </p>
    );
  }

  if (cart.appliedCoupons) {
    return (
      <p className="u-body u-body--xs whitespace-nowrap lg:u-body--s">
        Cupón aplicado
      </p>
    );
  }

  return (
    <button
      className="u-body u-body--xs flex flex-nowrap normal-case lg:u-body--s"
      {...(applyingCoupon && { disabled: true })}
      {...(!applyingCoupon && {
        onPointerDown: () => {
          setApplyingCoupon(true);
          applyCoupon(coupon.toLowerCase())
            .catch(() => null)
            .finally(() => {
              setApplyingCoupon(false);
            });
        },
      })}
    >
      {applyingCoupon ? (
        <p className="whitespace-nowrap">Aplicando código...</p>
      ) : (
        <div className="flex items-center gap-0.5">
          <p className="whitespace-nowrap">
            {message}: <span className="font-bold">{coupon}</span>
          </p>
          <Copy width={16} height={16} className="shrink-0" />
        </div>
      )}
    </button>
  );
};

const EmployeeSnackbar = (props: {
  snackbar: TypeSnackbarProps;
  user: { customerId: string; name?: string };
}) => {
  const {
    couponUsesLeftData,
    couponUsesLeftError,
    couponUsesLeftLoading,
    snackbarData,
    snackbarError,
    snackbarLoading,
  } = useEmployeeSnackbar({ id: props.user.customerId });

  if (snackbarError || couponUsesLeftError) {
    return (
      <div className="bg-status-error px-2 py-2 lg:py-3">
        <p className="u-body u-body--m text-center text-typo-alternative">
          Ha ocurrido un error obtiendo los datos.
        </p>
      </div>
    );
  }

  if (
    !snackbarData?.bcGeneralSettings.ajustesGenerales.employeeSnackbarActive
  ) {
    return null;
  }

  if (snackbarLoading || couponUsesLeftLoading) {
    return (
      <div className="bg-bg-tertiary px-2 py-2 lg:py-3">
        <p className="u-body u-body--s text-center text-typo-alternative">
          Hola{' '}
          <strong className="u-body--m font-extrabold text-secondary-500">
            {props?.user?.name ?? ''}.
          </strong>{' '}
          Se esta cargando tu contenido...
        </p>
      </div>
    );
  }

  if (
    couponUsesLeftData?.user.usesLeftCoupon === 0 &&
    !snackbarData?.bcGeneralSettings.ajustesGenerales.employeeSnackbar
      .extraCoupon
  ) {
    <StandardSnackbar snackbar={props.snackbar} />;
  }

  const snackbar =
    snackbarData.bcGeneralSettings.ajustesGenerales.employeeSnackbar;

  return (
    <div
      className="px-2 py-2 lg:py-3"
      style={{
        color: snackbar.textColor,
        backgroundColor: snackbar.backgroundColor,
      }}
    >
      <div className="u-wrapper flex justify-between gap-4">
        {snackbar.customMobileMessage ? (
          <>
            <div
              className="self-center [&_p]:u-body [&_p]:u-body--xs lg:[&_p]:u-body--s max-lg:hidden [&_a]:font-medium [&_a]:underline"
              dangerouslySetInnerHTML={{ __html: snackbar.message }}
            />
            <div
              className="self-center [&_p]:u-body [&_p]:u-body--xs lg:[&_p]:u-body--s lg:hidden [&_a]:font-medium [&_a]:underline"
              dangerouslySetInnerHTML={{ __html: snackbar.message }}
            />
          </>
        ) : (
          <div
            className="self-center [&_p]:u-body [&_p]:u-body--xs lg:[&_p]:u-body--s [&_a]:font-medium [&_a]:underline"
            dangerouslySetInnerHTML={{ __html: snackbar.message }}
          />
        )}

        <div className="flex shrink-0 items-end gap-0.5 max-lg:flex-col lg:gap-4">
          <p className="u-body u-body--xs lg:u-body--s">
            Usos restantes del cupón: {couponUsesLeftData?.user.usesLeftCoupon}
          </p>

          <div
            className="h-full w-px max-lg:hidden"
            style={{ backgroundColor: snackbar.textColor }}
          />

          {couponUsesLeftData!.user.usesLeftCoupon > 0 ? (
            <ApplyCoupon coupon={snackbar.coupon} message="Aplicar cupón" />
          ) : (
            couponUsesLeftData!.user.usesLeftCoupon === 0 && (
              <ApplyCoupon
                coupon={snackbar.extraCoupon!}
                message="Aplicar cupón"
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

const StandardSnackbar = ({ snackbar }: { snackbar: TypeSnackbarProps }) => {
  if (!snackbar.active) {
    return null;
  }

  if (
    snackbar.type === 'coupon' &&
    snackbar.countdown &&
    Date.now() >= Date.parse(snackbar.countdown)
  ) {
    return null;
  }

  if (snackbar.type === 'simple') {
    const Icon = Icons[snackbar.icon?.icon as keyof typeof Icons];
    return (
      <div
        style={{
          color: snackbar.textColor,
          backgroundColor: snackbar.backgroundColor,
        }}
      >
        <div className="flex justify-between lg:justify-center">
          <div className="flex flex-row items-center gap-6 py-2 pl-2 lg:py-3 lg:pr-2">
            <div className="flex flex-row items-center gap-2">
              {Icon && (
                <Icon
                  height={24}
                  width={24}
                  color={snackbar.textColor}
                  className="shrink-0"
                />
              )}

              {snackbar.customMobileMessage ? (
                <>
                  <div
                    className="[&_p]:u-body [&_p]:u-body--xl max-lg:hidden"
                    dangerouslySetInnerHTML={{ __html: snackbar.message }}
                  />
                  <div
                    className="[&_p]:u-body [&_p]:u-body--s lg:hidden"
                    dangerouslySetInnerHTML={{ __html: snackbar.mobileMessage }}
                  />
                </>
              ) : (
                <div
                  className="[&_p]:u-body [&_p]:u-body--xl [&_a]:underline"
                  dangerouslySetInnerHTML={{ __html: snackbar.message }}
                />
              )}
            </div>

            {snackbar.cta?.label && (
              <NextLink
                href={removeBaseURL(snackbar.cta?.link?.url ?? '/')}
                passHref
              >
                <Button
                  as="a"
                  size={snackbar.cta.size as ButtonProps['size']}
                  negative={snackbar.cta.negativo}
                  variant={snackbar.cta.style as ButtonProps['variant']}
                  className="!hidden lg:!flex"
                >
                  {snackbar.cta.label}
                </Button>
              </NextLink>
            )}
          </div>

          {snackbar.cta?.label && (
            <NextLink href={removeBaseURL(snackbar.cta.link?.url ?? '/')}>
              <a
                role="button"
                className="flex w-7 shrink-0 bg-primary-900 lg:hidden"
              >
                <ChevronRight
                  height={12}
                  width={12}
                  color="#FFFFFF"
                  className="mx-auto self-center"
                />
              </a>
            </NextLink>
          )}
        </div>
      </div>
    );
  }

  if (snackbar.type === 'coupon') {
    return (
      <div
        className="px-2 py-2 lg:py-3"
        style={{
          color: snackbar.textColor,
          backgroundColor: snackbar.backgroundColor,
        }}
      >
        <div
          className={oneLine`u-wrapper flex gap-2 lg:gap-20 lg:gap-7 ${
            snackbar.countdown || snackbar.coupon
              ? 'justify-between'
              : 'justify-center'
          }`}
        >
          {snackbar.customMobileMessage ? (
            <>
              <div
                dangerouslySetInnerHTML={{ __html: snackbar.message }}
                className="self-center [&_p]:u-body [&_p]:u-body--xs lg:[&_p]:u-body--s max-lg:hidden [&_a]:font-medium [&_a]:underline"
              />
              <div
                dangerouslySetInnerHTML={{ __html: snackbar.mobileMessage }}
                className="self-center [&_p]:u-body [&_p]:u-body--xs lg:[&_p]:u-body--s lg:hidden [&_a]:font-medium [&_a]:underline"
              />
            </>
          ) : (
            <div
              dangerouslySetInnerHTML={{ __html: snackbar.message }}
              className="self-center [&_p]:u-body [&_p]:u-body--xs lg:[&_p]:u-body--s [&_a]:font-medium [&_a]:underline"
            />
          )}

          {snackbar.countdown || snackbar.coupon ? (
            <div className="flex shrink-0 items-end gap-0.5 max-lg:flex-col lg:gap-4">
              {snackbar.countdown && (
                <div className="flex h-5 min-w-32 flex-row items-center justify-end gap-2">
                  <Reloj
                    color={snackbar.textColor}
                    width={16}
                    height={16}
                    className="shrink-0"
                  />
                  <Countdown date={snackbar.countdown} />
                </div>
              )}

              {snackbar.coupon && (
                <>
                  <div
                    className="h-full w-px max-lg:hidden"
                    style={{ backgroundColor: snackbar.textColor }}
                  />
                  <ApplyCoupon coupon={snackbar.coupon} message="Cupón" />
                </>
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
};

const Snackbar = (props: TypeSnackbarProps) => {
  const { isEmployee, user } = useEmployee();

  if (isEmployee) {
    return <EmployeeSnackbar snackbar={props} user={user as any} />;
  }

  return <StandardSnackbar snackbar={props} />;
};

export { Snackbar };
