import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono LinkedIn
 */
export const LinkedIn = ({
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
  ...props
}: IconType): JSX.Element => (
  <svg width={width} height={height} {...props} fill="none" viewBox="0 0 24 24">
    <path
      fill={color}
      d="M12 9.55C12.917 8.613 14.111 8 15.5 8a5.5 5.5 0 0 1 5.5 5.5V21h-2v-7.5a3.5 3.5 0 1 0-7 0V21h-2V8.5h2v1.05ZM5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm-1 2h2V21H4V8.5Z"
    />
  </svg>
);
