import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono ArrowLeft
 */
export const ArrowLeft = ({
  className,
  width = 24,
  height = 15,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 15"
  >
    <path
      d="M-6.6113e-05 7.23663C4.99841 7.23663 9.04272 10.4713 9.04272 14.4609"
      stroke={color}
      strokeMiterlimit="10"
    />
    <path
      d="M-6.51551e-05 7.23047C4.99841 7.23047 9.04272 3.99561 9.04272 1.89372e-05"
      stroke={color}
      strokeMiterlimit="10"
    />
    <path
      d="M0.607301 7.23051L23.5037 7.23051"
      stroke={color}
      strokeMiterlimit="10"
    />
  </svg>
);
