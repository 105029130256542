import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono Settings
 */
export const Settings = ({
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
  ...props
}: IconType): JSX.Element => (
  <svg viewBox="0 0 24 24" fill="none" width={width} height={height} {...props}>
    <path
      d="M21 18V21H19V18H17V16H23V18H21ZM5 18V21H3V18H1V16H7V18H5ZM11 6V3H13V6H15V8H9V6H11ZM11 10H13V21H11V10ZM3 14V3H5V14H3ZM19 14V3H21V14H19Z"
      fill={color}
    />
  </svg>
);
