import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono Advantage
 */
export const Less = ({
  className,
  width = 12,
  height = 12,
  color = defaultProps.color,
  ...props
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path d="M2.5 5.5H9.5V6.5H2.5V5.5Z" fill={color} />
  </svg>
);
