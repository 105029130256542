import type { IconType } from './types';

/**
 * Icono GooglePay
 */
export const GooglePay = ({ className }: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width="70px"
    height="30px"
    fill="none"
    viewBox="0 0 100 30"
  >
    <path
      d="M37.8 19.7V29h-3V6h7.8c1.9 0 3.7.7 5.1 2 1.4 1.2 2.1 3 2.1 4.9 0 1.9-.7 3.6-2.1 4.9-1.4 1.3-3.1 2-5.1 2l-4.8-.1zm0-10.9v8h5c1.1 0 2.2-.4 2.9-1.2 1.6-1.5 1.6-4 .1-5.5l-.1-.1c-.8-.8-1.8-1.3-2.9-1.2h-5zM56.7 12.8c2.2 0 3.9.6 5.2 1.8s1.9 2.8 1.9 4.8V29H61v-2.2h-.1a5.68 5.68 0 0 1-4.9 2.7c-1.7 0-3.2-.5-4.4-1.5-1.1-1-1.8-2.4-1.8-3.9 0-1.6.6-2.9 1.8-3.9 1.2-1 2.9-1.4 4.9-1.4 1.8 0 3.2.3 4.3 1v-.7c0-1-.4-2-1.2-2.6-.8-.7-1.8-1.1-2.9-1.1-1.7 0-3 .7-3.9 2.1l-2.6-1.6c1.6-2.1 3.7-3.1 6.5-3.1zm-3.8 11.4c0 .8.4 1.5 1 1.9a4 4 0 0 0 2.3.8c1.2 0 2.4-.5 3.3-1.4 1-.9 1.5-2 1.5-3.2-.9-.7-2.2-1.1-3.9-1.1-1.2 0-2.2.3-3 .9-.8.5-1.2 1.2-1.2 2.1zM80 13.3 70.1 36h-3l3.7-7.9-6.5-14.7h3.2l4.7 11.3h.1l4.6-11.3H80z"
      fill="#5f6368"
    />
    <path
      d="M25.9 17.7c0-.9-.1-1.8-.2-2.7H13.2v5.1h7.1c-.3 1.6-1.2 3.1-2.6 4v3.3H22c2.5-2.3 3.9-5.7 3.9-9.7z"
      fill="#4285f4"
    />
    <path
      d="M13.2 30.6c3.6 0 6.6-1.2 8.8-3.2l-4.3-3.3c-1.2.8-2.7 1.3-4.5 1.3-3.4 0-6.4-2.3-7.4-5.5H1.4v3.4c2.3 4.5 6.8 7.3 11.8 7.3z"
      fill="#34a853"
    />
    <path
      d="M5.8 19.9c-.6-1.6-.6-3.4 0-5.1v-3.4H1.4c-1.9 3.7-1.9 8.1 0 11.9l4.4-3.4z"
      fill="#fbbc04"
    />
    <path
      d="M13.2 9.4c1.9 0 3.7.7 5.1 2l3.8-3.8c-2.4-2.2-5.6-3.5-8.8-3.4-5 0-9.6 2.8-11.8 7.3l4.4 3.4c.9-3.2 3.9-5.5 7.3-5.5z"
      fill="#ea4335"
    />
  </svg>
);
