import type { IconType } from './types';

/**
 * Icono OrderWithIncidence
 */
export const OrderWithIncidence = ({
  className,
  width = 12,
  height = 12,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    viewBox="0 0 26 23"
    fill="none"
  >
    <path
      fill="#262626"
      fillRule="evenodd"
      d="M4.503 14.891a.572.572 0 0 0-.568.576v2.302c0 .318.255.575.568.575h3.789a.572.572 0 0 0 .568-.575v-2.302a.572.572 0 0 0-.568-.576H4.503Zm.569 2.302v-1.15h2.651v1.15H5.072Z"
      clipRule="evenodd"
    />
    <path
      fill="#262626"
      d="M11.512 15.467c0-.318.254-.576.568-.576h2.652c.313 0 .568.258.568.576a.572.572 0 0 1-.568.575H12.08a.572.572 0 0 1-.568-.575ZM12.08 17.193a.572.572 0 0 0-.568.576c0 .318.254.575.568.575h2.652a.572.572 0 0 0 .568-.575.572.572 0 0 0-.568-.576H12.08Z"
    />
    <path
      fill="#262626"
      fillRule="evenodd"
      d="M3.367 5.568a.68.68 0 0 0-.57.312L.901 8.816a.697.697 0 0 0-.111.377V20.71c0 .382.305.691.682.691h16.053c.376 0 .682-.31.682-.69V9.193a.697.697 0 0 0-.09-.343L16.46 5.915a.68.68 0 0 0-.592-.347H3.367Zm-.435 2.935 1.003-1.554H15.3l.877 1.554H2.932Zm-.558 1.381h14.25v9.934H2.374V9.884Z"
      clipRule="evenodd"
    />
    <g clipPath="M12 .984h14v14H12z">
      <path
        fill="#D32929"
        d="M19 13.818A5.833 5.833 0 1 1 19 2.15a5.833 5.833 0 0 1 0 11.667Zm-.584-4.084v1.167h1.167V9.734h-1.167Zm0-4.666v3.5h1.167v-3.5h-1.167Z"
      />
    </g>
  </svg>
);
