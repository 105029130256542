import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * AlertFill
 */
export const AlertFill = ({
  className,
  width = 24,
  height = 24,
  color = defaultProps.color,
}: IconType) => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <g clipPath="url(#a)">
      <path
        fill={color}
        d="m12.9 3 9.5 16.5a1 1 0 0 1-.9 1.5h-19a1 1 0 0 1-.9-1.5L11.1 3A1 1 0 0 1 13 3ZM11 16v2h2v-2h-2Zm0-7v5h2V9h-2Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
