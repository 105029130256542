import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono ArrowLeftLine
 */
export const ArrowLeftLine = ({
  className,
  width = 20,
  height = 20,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 20 20"
  >
    <g clipPath="url(#a)">
      <path
        stroke={color}
        fill="#262626"
        d="M6.523 9.167h10.143v1.666H6.523l4.47 4.47-1.178 1.179L3.333 10l6.482-6.482 1.178 1.179-4.47 4.47Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
