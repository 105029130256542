import type { IconType } from '@/icons/types';

export const SocialGoogle = ({
  className,
  width = 12,
  color = '#262626',
}: IconType): JSX.Element => (
  <svg
    width={width}
    {...(className && { className })}
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M0.787659 3.38085C1.273 2.41441 2.01754 1.60199 2.93808 1.03438C3.85861 0.466769 4.91886 0.166347 6.00033 0.166687C7.57241 0.166687 8.89308 0.744187 9.90283 1.68627L8.23041 3.35927C7.62549 2.78119 6.85666 2.4866 6.00033 2.4866C4.48074 2.4866 3.19449 3.51327 2.73658 4.89169C2.61991 5.24169 2.55341 5.61502 2.55341 6.00002C2.55341 6.38502 2.61991 6.75835 2.73658 7.10835C3.19508 8.48735 4.48074 9.51344 6.00033 9.51344C6.78491 9.51344 7.45283 9.30635 7.97549 8.95635C8.2785 8.75686 8.5379 8.49799 8.73803 8.19539C8.93815 7.8928 9.07484 7.55277 9.13983 7.19585H6.00033V4.93952H11.4942C11.563 5.32102 11.6003 5.71885 11.6003 6.13244C11.6003 7.90927 10.9645 9.40494 9.86083 10.4199C8.89599 11.3113 7.57533 11.8334 6.00033 11.8334C5.2342 11.8337 4.47551 11.683 3.76764 11.3899C3.05977 11.0969 2.41659 10.6672 1.87485 10.1255C1.33312 9.58376 0.903448 8.94057 0.610404 8.2327C0.31736 7.52483 0.166686 6.76615 0.166993 6.00002C0.166993 5.05852 0.392159 4.16835 0.787659 3.38085Z"
      fill={color}
    />
  </svg>
);
