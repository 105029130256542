import type { IconType } from './types';

/**
 * Icono PayPal
 */
export const PayPalFull = ({
  className,
  width = 83,
}: IconType): JSX.Element => (
  <svg
    width={width}
    viewBox="0 0 83 24"
    fill="none"
    {...(className && { className })}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8463 7.7666H18.4246C20.8828 7.7666 21.8081 8.99325 21.6651 10.7954C21.4288 13.7705 19.6041 15.4166 17.1837 15.4166H15.9618C15.6297 15.4166 15.4063 15.6332 15.3165 16.2203L14.7977 19.6335C14.7634 19.8548 14.6452 19.983 14.468 20.0005H11.5914C11.3208 20.0005 11.2251 19.7966 11.296 19.3551L13.0498 8.41313C13.1183 7.97512 13.3618 7.7666 13.8463 7.7666Z"
      fill="#009EE3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.7232 7.56348C35.2678 7.56348 36.693 8.3894 36.498 10.4478C36.2617 12.8941 34.9321 14.2477 32.8344 14.2535H31.0015C30.7379 14.2535 30.6103 14.4655 30.5417 14.9001L30.1872 17.1215C30.134 17.457 29.9591 17.6224 29.7015 17.6224H27.9961C27.7243 17.6224 27.6298 17.4512 27.69 17.068L29.0976 8.16457C29.1673 7.72656 29.3339 7.56348 29.6377 7.56348H33.7196H33.7232ZM30.9459 12.3303H32.3345C33.2032 12.2977 33.7799 11.7047 33.8378 10.6353C33.8732 9.97483 33.4206 9.50188 32.7009 9.50537L31.3938 9.5112L30.9459 12.3303ZM41.1342 16.941C41.2902 16.8012 41.4486 16.729 41.4262 16.9014L41.3706 17.3137C41.3422 17.5293 41.4285 17.6434 41.6318 17.6434H43.1469C43.4021 17.6434 43.5262 17.5421 43.5888 17.153L44.5225 11.3774C44.5697 11.0873 44.4977 10.9452 44.2743 10.9452H42.608C42.4579 10.9452 42.3846 11.0279 42.3456 11.2539L42.2841 11.6092C42.2522 11.7944 42.166 11.827 42.0856 11.6407C41.8031 10.9813 41.0822 10.6854 40.0765 10.7087C37.7401 10.7565 36.1648 12.505 35.9958 14.7463C35.8658 16.4797 37.1256 17.8414 38.7872 17.8414C39.9926 17.8414 40.5315 17.492 41.139 16.9445L41.1342 16.941ZM39.865 16.0522C38.8593 16.0522 38.1585 15.2612 38.3038 14.292C38.4492 13.3228 39.3899 12.5318 40.3956 12.5318C41.4013 12.5318 42.1021 13.3228 41.9568 14.292C41.8114 15.2612 40.8719 16.0522 39.865 16.0522ZM47.4876 10.9266H45.9513C45.6346 10.9266 45.5057 11.1595 45.6062 11.4461L47.5136 16.9515L45.6428 19.5713C45.4856 19.7903 45.6074 19.9895 45.8284 19.9895H47.555C47.6556 20.001 47.7575 19.9837 47.8484 19.9397C47.9393 19.8957 48.0155 19.8269 48.0679 19.7414L53.9344 11.4473C54.1152 11.1922 54.0301 10.9242 53.7335 10.9242H52.099C51.8189 10.9242 51.7067 11.0337 51.5459 11.2632L49.0996 14.7579L48.0064 11.2551C47.9426 11.0431 47.7831 10.9266 47.4888 10.9266H47.4876Z"
      fill="#113984"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.0372 7.56386C61.5818 7.56386 63.007 8.38978 62.812 10.4482C62.5757 12.8945 61.2461 14.2481 59.1484 14.2539H57.3166C57.0531 14.2539 56.9255 14.4659 56.8569 14.9004L56.5024 17.1219C56.4492 17.4574 56.2743 17.6228 56.0167 17.6228H54.3113C54.0395 17.6228 53.945 17.4516 54.0052 17.0683L55.4151 8.16262C55.4848 7.72461 55.6515 7.56152 55.9552 7.56152H60.0372V7.56386ZM57.2599 12.3307H58.6485C59.5172 12.298 60.0939 11.7051 60.1518 10.6357C60.1872 9.97521 59.7346 9.50226 59.0149 9.50575L57.7078 9.51158L57.2599 12.3307V12.3307ZM67.4482 16.9414C67.6042 16.8016 67.7626 16.7293 67.7401 16.9017L67.6846 17.3141C67.6562 17.5296 67.7425 17.6438 67.9458 17.6438H69.4608C69.7161 17.6438 69.8402 17.5424 69.9028 17.1534L70.8365 11.3778C70.8837 11.0877 70.8117 10.9456 70.5883 10.9456H68.9243C68.7742 10.9456 68.701 11.0283 68.662 11.2543L68.6005 11.6096C68.5686 11.7948 68.4823 11.8274 68.402 11.641C68.1195 10.9817 67.3986 10.6858 66.3929 10.7091C64.0565 10.7569 62.4811 12.5054 62.3121 14.7467C62.1821 16.4801 63.4419 17.8418 65.1035 17.8418C66.309 17.8418 66.8479 17.4924 67.4553 16.9449L67.4482 16.9414ZM66.1802 16.0525C65.1745 16.0525 64.4736 15.2616 64.619 14.2924C64.7644 13.3232 65.7051 12.5322 66.7108 12.5322C67.7165 12.5322 68.4173 13.3232 68.272 14.2924C68.1266 15.2616 67.1859 16.0525 66.1802 16.0525ZM73.1682 17.6531H71.4191C71.3887 17.6545 71.3583 17.6492 71.3302 17.6376C71.3021 17.626 71.2769 17.6085 71.2565 17.5862C71.2361 17.564 71.2209 17.5376 71.212 17.5088C71.2031 17.4801 71.2008 17.4498 71.2052 17.4201L72.7415 7.82596C72.7562 7.76048 72.7929 7.70182 72.8457 7.65952C72.8985 7.61722 72.9642 7.59377 73.0323 7.59298H74.7813C74.8118 7.59164 74.8421 7.59693 74.8702 7.60849C74.8983 7.62005 74.9235 7.63758 74.9439 7.65984C74.9643 7.6821 74.9795 7.70853 74.9884 7.73724C74.9973 7.76596 74.9996 7.79625 74.9952 7.82596L73.4589 17.4201C73.4447 17.486 73.4082 17.5452 73.3554 17.588C73.3025 17.6308 73.2365 17.6545 73.1682 17.6554V17.6531Z"
      fill="#009EE3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9834 4H15.5664C16.857 4 18.3886 4.04077 19.412 4.93193C20.0963 5.52719 20.4556 6.47426 20.3728 7.49472C20.0916 10.944 17.9986 12.8766 15.1906 12.8766H12.931C12.5458 12.8766 12.2917 13.1282 12.1829 13.8085L11.5519 17.7692C11.5105 18.0255 11.3982 18.1769 11.1973 18.1956H8.36926C8.05608 18.1956 7.94499 17.9626 8.02653 17.4477L10.0592 4.75253C10.1408 4.2423 10.4256 4 10.9834 4Z"
      fill="#113984"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2485 13.4063L13.0486 8.41234C13.1183 7.97434 13.3618 7.76465 13.8463 7.76465H18.4246C19.1822 7.76465 19.7955 7.88114 20.2753 8.09665C19.8156 11.1673 17.8007 12.8728 15.1629 12.8728H12.9068C12.6043 12.8739 12.3821 13.023 12.2485 13.4063Z"
      fill="#172C70"
    />
  </svg>
);
