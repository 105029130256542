import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono UserActive
 */
export const UserActive = ({
  className,
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M12 2.118c5.52 0 10 4.48 10 10s-4.48 10-10 10-10-4.48-10-10 4.48-10 10-10ZM6.023 15.534c1.468 2.19 3.672 3.584 6.137 3.584 2.464 0 4.669-1.393 6.136-3.584a8.968 8.968 0 0 0-6.136-2.416 8.968 8.968 0 0 0-6.137 2.416ZM12 11.118a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
    />
    <circle cx="20.5" cy="4.618" r="3.5" fill="#F68217" />
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .118h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
