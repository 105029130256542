import type { IconType } from './types';

/**
 * Icono ChevronDown
 */
export const CheckOk = ({
  className,
  width = 15,
  height = 11,
  color = '#F68217',
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    viewBox="0 0 15 11"
    fill="none"
  >
    <path
      d="M5.33336 8.64332L12.9934 0.982483L14.1725 2.16082L5.33336 11L0.0300293 5.69665L1.20836 4.51832L5.33336 8.64332Z"
      fill={color}
    />
  </svg>
);
