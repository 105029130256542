import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono TikTok
 */
export const TikTok = ({
  className,
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 23 24"
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M22 7.237v4.706a11.605 11.605 0 0 1-5.833-2.276v5.25a7.583 7.583 0 1 1-9.334-7.38v5.046a2.916 2.916 0 1 0 4.667 2.334V1.5h4.764A7.006 7.006 0 0 0 22 7.237Z"
    />
  </svg>
);
