import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono ArrowRightCircleLine
 */
export const ArrowRightCircleLine = ({
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
  ...props
}: IconType): JSX.Element => (
  <svg {...props} width={width} height={height} viewBox="0 0 16 17" fill="none">
    <g fill={color} d="M0 .38h16v16H0z">
      <path
        fill={color}
        d="M8 7.713v-2l2.667 2.667L8 11.046v-2H5.333V7.713H8Zm0-6a6.67 6.67 0 0 1 6.667 6.667A6.669 6.669 0 0 1 8 15.046 6.669 6.669 0 0 1 1.333 8.38 6.67 6.67 0 0 1 8 1.713Zm0 12a5.332 5.332 0 0 0 5.334-5.333A5.332 5.332 0 0 0 8 3.046 5.332 5.332 0 0 0 2.667 8.38 5.332 5.332 0 0 0 8 13.713Z"
      />
    </g>
  </svg>
);
