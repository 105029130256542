import { useInsertionEffect } from 'react';
import { OrganizationJsonLd } from 'next-seo';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { SymbolCart } from '@/icons';
import { Snackbar } from '@/organisms';

import { routes } from '@/lib/routes';
import { getRealURL } from '@/lib/utils';
import Layout from './Layout';

import type { TypeSnackbarProps } from '@/organisms/snackbar';
import type { PropsWithChildren } from '@/types';
import type { TypeImageWordpress } from '../types';

const Footer = dynamic(() => import('@/organisms/footer'));
const FooterSimple = dynamic(() => import('@/organisms/footer-simple'));
const Header = dynamic(() => import('@/organisms/header'));
const HeaderSimple = dynamic(() => import('@/organisms/header-simple'));

type TypeLayout = PropsWithChildren<{
  snackbarActive?: boolean;
  snackbar?: TypeSnackbarProps;
  pageTheme?: {
    headerStrip: {
      desktopImage: TypeImageWordpress;
      mobileImage: TypeImageWordpress;
    };
    themeColors: {
      background: string;
      actionColor: string;
    };
  };
  pageHeaderFooter?: {
    header: 'complete' | 'simple' | 'hide';
    footer: 'complete' | 'simple' | 'hide';
  };
}>;

export const getBaseLayout = ({
  children,
  snackbarActive,
  snackbar,
  pageTheme,
  pageHeaderFooter,
  ...props
}: any) => (
  <Layout {...props}>
    {/** TODO: conectar con ACF */}
    <OrganizationJsonLd
      type="Organization"
      name="Bigcrafters"
      url={process.env.NEXT_PUBLIC_FRONT_URL ?? ''}
      logo={props?.logo?.sourceUrl}
      description="Plataforma de comercio electrónico orientada a la venta de productos artesanales, así como a impulsar la presencia digital de pequeños productores artesanos."
      sameAs={props?.socialNetworks?.map(
        (social: { icon: string; link: string }) => social?.link,
      )}
      knowsAbout={['productos artesanos']}
      contactPoint={[
        {
          contactType: 'customer service',
          telephone: '+34 900 190 006',
          url: getRealURL(routes.contact),
          availableLanguage: 'Spanish',
        },
      ]}
    />

    <BaseLayout
      snackbar={snackbar}
      snackbarActive={snackbarActive}
      pageTheme={pageTheme}
      pageHeaderFooter={pageHeaderFooter}
    >
      {children}
    </BaseLayout>
  </Layout>
);

const BaseLayout = ({
  children,
  snackbarActive,
  snackbar,
  pageTheme,
  pageHeaderFooter,
}: TypeLayout) => {
  const router = useRouter();

  useInsertionEffect(() => {
    if (!pageTheme) return;

    const styleElement = document.createElement('style');
    styleElement.textContent = `
      #__next {
        background-color: ${pageTheme.themeColors.background};
      }

      :root {
        --tw-color-bg-action: ${pageTheme.themeColors.actionColor};
      }
    `;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, [router]);

  return (
    <>
      <SymbolCart />

      {pageHeaderFooter?.header === 'complete' ? (
        <Header customClaim={pageTheme?.headerStrip} />
      ) : pageHeaderFooter?.header === 'simple' ? (
        <HeaderSimple customClaim={pageTheme?.headerStrip} />
      ) : pageHeaderFooter?.header === 'hide' ? null : (
        <Header />
      )}

      <main className="grow">
        <Snackbar {...snackbar!} active={Boolean(snackbarActive)} />
        {children}
      </main>

      {pageHeaderFooter?.footer === 'complete' ? (
        <Footer />
      ) : pageHeaderFooter?.footer === 'simple' ? (
        <FooterSimple />
      ) : pageHeaderFooter?.footer === 'hide' ? null : (
        <Footer />
      )}
    </>
  );
};

export default BaseLayout;
