import type { IconType } from './types';

/**
 * Icono Filter
 */
export const GestionConsentimiento = ({
  className,
  width = 20,
  color = '#262626',
}: IconType): JSX.Element => (
  <svg
    width={width}
    viewBox="0 0 20 20"
    fill="none"
    {...(className && { className })}
  >
    <path
      d="M4.455 16.2454L0 19.7454V1.24536C0 0.980145 0.105357 0.725791 0.292893 0.538254C0.48043 0.350718 0.734784 0.245361 1 0.245361H19C19.2652 0.245361 19.5196 0.350718 19.7071 0.538254C19.8946 0.725791 20 0.980145 20 1.24536V15.2454C20 15.5106 19.8946 15.7649 19.7071 15.9525C19.5196 16.14 19.2652 16.2454 19 16.2454H4.455ZM3.763 14.2454H18V2.24536H2V15.6304L3.763 14.2454ZM9.293 9.36636L13.536 5.12436L14.95 6.53836L9.293 12.1954L5.403 8.30536L6.818 6.89136L9.293 9.36636Z"
      fill={color}
    />
  </svg>
);
