import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono Facebook
 */
export const Facebook = ({
  className,
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 25"
  >
    <path
      d="M14 19.417h5v-14H5v14h7v-5h-2v-2h2v-1.654c0-1.337.14-1.822.4-2.311.256-.484.652-.88 1.136-1.135.382-.205.857-.328 1.687-.381.329-.021.755.005 1.278.08v1.9H16c-.917 0-1.296.043-1.522.164a.727.727 0 0 0-.314.314c-.12.226-.164.45-.164 1.368v1.655h2.5l-.5 2h-2v5Zm-10-16h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-16a1 1 0 0 1 1-1Z"
      fill={color}
    />
  </svg>
);
