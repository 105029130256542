import type { IconType } from './types';

/**
 * Icono Advantage
 */
export const Reloj = ({
  className,
  width = 17,
  color = '#262626',
}: IconType): JSX.Element => (
  <svg
    width={width}
    {...(className && { className })}
    viewBox="0 0 17 17"
    fill="none"
  >
    <path
      d="M8.49992 16.8029C4.12754 16.8029 0.583252 13.2586 0.583252 8.88621C0.583252 4.51384 4.12754 0.969543 8.49992 0.969543C12.8723 0.969543 16.4166 4.51384 16.4166 8.88621C16.4166 13.2586 12.8723 16.8029 8.49992 16.8029ZM8.49992 15.2195C10.1796 15.2195 11.7905 14.5523 12.9783 13.3646C14.166 12.1768 14.8333 10.5659 14.8333 8.88621C14.8333 7.20651 14.166 5.5956 12.9783 4.40787C11.7905 3.22014 10.1796 2.55288 8.49992 2.55288C6.82021 2.55288 5.20931 3.22014 4.02158 4.40787C2.83385 5.5956 2.16659 7.20651 2.16659 8.88621C2.16659 10.5659 2.83385 12.1768 4.02158 13.3646C5.20931 14.5523 6.82021 15.2195 8.49992 15.2195ZM9.29159 8.88621H12.4583V10.4695H7.70825V4.92788H9.29159V8.88621Z"
      fill={color}
    />
  </svg>
);
