import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Stop
 */
export const Stop = ({
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
  ...props
}: IconType): JSX.Element => (
  <svg fill="none" viewBox="0 0 24 24" width={width} height={height} {...props}>
    <path
      fill={color}
      d="M7 7v10h10V7H7ZM6 5h12a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z"
    />
  </svg>
);
