import type { IconType } from '@/icons/types';

export const SocialTwitter = ({
  className,
  width = 12,
  color = '#262626',
}: IconType): JSX.Element => (
  <svg
    width={width}
    {...(className && { className })}
    viewBox="0 0 12 10"
    fill="none"
  >
    <path
      d="M11.9282 1.29935C11.4828 1.49634 11.0106 1.62571 10.527 1.68319C11.0367 1.37835 11.4182 0.89861 11.6003 0.333352C11.122 0.618018 10.5976 0.817518 10.051 0.925435C9.68386 0.532606 9.19721 0.27208 8.66673 0.184359C8.13624 0.0966386 7.59163 0.186638 7.11757 0.440366C6.64351 0.694094 6.26656 1.09733 6.04532 1.5874C5.82408 2.07746 5.77094 2.62689 5.89417 3.15027C4.92415 3.10165 3.9752 2.84957 3.10892 2.41041C2.24264 1.97124 1.47841 1.3548 0.865836 0.601102C0.649005 0.973531 0.53506 1.3969 0.535669 1.82785C0.535669 2.67368 0.966169 3.42094 1.62067 3.85844C1.23334 3.84624 0.854538 3.74164 0.515836 3.55335V3.58368C0.515953 4.14701 0.710885 4.69296 1.06758 5.12897C1.42428 5.56498 1.92078 5.86421 2.47292 5.97594C2.11336 6.07337 1.73635 6.08774 1.37042 6.01794C1.52609 6.50282 1.82951 6.92688 2.23818 7.23075C2.64686 7.53461 3.14033 7.70307 3.6495 7.71252C3.14345 8.10996 2.56402 8.40376 1.94435 8.57712C1.32468 8.75048 0.67691 8.8 0.0380859 8.72285C1.15324 9.44002 2.45139 9.82076 3.77725 9.81952C8.26484 9.81952 10.7189 6.10194 10.7189 2.87785C10.7189 2.77285 10.716 2.66669 10.7113 2.56285C11.189 2.21762 11.6013 1.78995 11.9288 1.29993L11.9282 1.29935Z"
      fill={color}
    />
  </svg>
);
