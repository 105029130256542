import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono BizumSmall
 */
export const BizumSmall = ({
  className,
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      d="M7.478 3.74A1.74 1.74 0 1 1 4 3.74a1.74 1.74 0 0 1 3.478 0ZM19.652 20.26a1.74 1.74 0 1 1-3.478.001 1.74 1.74 0 0 1 3.478 0ZM11.188 4.297c.652.46.807 1.363.347 2.015L9.529 9.153a1.446 1.446 0 0 1-2.362-1.667l2.006-2.842a1.446 1.446 0 0 1 2.015-.347ZM16.337 5.167c.652.46.807 1.362.347 2.015L8.659 18.547a1.446 1.446 0 0 1-2.362-1.667l8.025-11.366a1.446 1.446 0 0 1 2.015-.347ZM15.536 14.732c.652.46.807 1.363.347 2.015l-2.006 2.841a1.446 1.446 0 0 1-2.362-1.667l2.006-2.842a1.446 1.446 0 0 1 2.015-.347Z"
      fill={color}
    />
  </svg>
);
