import type { IconType } from './types';

/**
 * Icono Search
 */
export const SocialFacebook = ({
  className,
  width = 7,
  color = '#262626',
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    viewBox="0 0 7 12"
    fill="none"
  >
    <path
      d="M4.16634 6.87502H5.62468L6.20801 4.54169H4.16634V3.37502C4.16634 2.77419 4.16634 2.20835 5.33301 2.20835H6.20801V0.248354C6.01784 0.22327 5.29976 0.166687 4.54143 0.166687C2.95767 0.166687 1.83301 1.13327 1.83301 2.90835V4.54169H0.0830078V6.87502H1.83301V11.8334H4.16634V6.87502Z"
      fill={color}
    />
  </svg>
);
