import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Send
 */
export const Send = ({
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
  ...props
}: IconType): JSX.Element => (
  <svg width={width} height={height} {...props} fill="none" viewBox="0 0 24 24">
    <path
      fill={color}
      d="m3.741 1.408 18.462 10.154a.5.5 0 0 1 0 .876L3.741 22.592A.5.5 0 0 1 3 22.154V1.846a.5.5 0 0 1 .741-.438ZM5 13v6.617L18.85 12 5 4.383V11h5v2H5Z"
    />
  </svg>
);
