import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono Info
 */
export const Info = ({
  className,
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      d="M16 29.335c-7.364 0-13.333-5.97-13.333-13.334S8.636 2.668 16 2.668s13.333 5.97 13.333 13.333c0 7.364-5.969 13.334-13.333 13.334Zm0-2.667a10.667 10.667 0 1 0 0-21.333 10.667 10.667 0 0 0 0 21.333ZM14.667 9.335h2.666V12h-2.666V9.335Zm0 5.333h2.666v8h-2.666v-8Z"
      fill={color}
    />
  </svg>
);
