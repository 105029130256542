import type { IconType } from './types';

/**
 * Icono EmtyCart
 */
export const OjoCerrado = ({
  className,
  width = 22,
  color = '#262626',
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      d="M16.8817 18.2969C15.1229 19.4125 13.0824 20.0033 10.9997 19.9999C5.60766 19.9999 1.12166 16.1199 0.180664 10.9999C0.610694 8.67066 1.78229 6.54284 3.52066 4.93394L0.391664 1.80794L1.80666 0.392944L21.6057 20.1929L20.1907 21.6069L16.8807 18.2969H16.8817ZM4.93466 6.34994C3.57567 7.58553 2.62898 9.20873 2.22266 10.9999C2.53496 12.3664 3.16193 13.6411 4.05367 14.7225C4.94541 15.804 6.07729 16.6623 7.35922 17.2293C8.64114 17.7962 10.0377 18.056 11.4378 17.9881C12.8378 17.9201 14.2026 17.5263 15.4237 16.8379L13.3957 14.8099C12.5324 15.3538 11.5099 15.588 10.4959 15.4744C9.48194 15.3607 8.53671 14.9058 7.81523 14.1844C7.09376 13.4629 6.63889 12.5177 6.52523 11.5037C6.41156 10.4897 6.64585 9.46725 7.18966 8.60394L4.93466 6.34994ZM11.9137 13.3279L8.67166 10.0859C8.49373 10.5389 8.45185 11.0339 8.55117 11.5103C8.65049 11.9867 8.88668 12.4237 9.23078 12.7678C9.57488 13.1119 10.0119 13.3481 10.4883 13.4474C10.9647 13.5468 11.4597 13.5049 11.9127 13.3269L11.9137 13.3279ZM19.8067 15.5919L18.3757 14.1619C19.0441 13.2092 19.5201 12.1351 19.7767 10.9999C19.5049 9.80967 18.9939 8.68709 18.2748 7.70044C17.5557 6.71379 16.6435 5.88367 15.5935 5.26055C14.5436 4.63743 13.378 4.23431 12.1674 4.07571C10.9568 3.9171 9.72667 4.00632 8.55166 4.33794L6.97366 2.75994C8.22066 2.26994 9.57966 1.99994 10.9997 1.99994C16.3917 1.99994 20.8777 5.87994 21.8187 10.9999C21.5122 12.6656 20.8235 14.2375 19.8067 15.5919ZM10.7227 6.50794C11.3592 6.46861 11.9968 6.56501 12.5932 6.79076C13.1896 7.01651 13.7313 7.36646 14.1822 7.81739C14.6332 8.26833 14.9831 8.80997 15.2088 9.4064C15.4346 10.0028 15.531 10.6404 15.4917 11.2769L10.7217 6.50794H10.7227Z"
      fill={color}
    />
  </svg>
);
