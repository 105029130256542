import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono Trash
 */
export const Trash = ({
  className,
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    version="1.0"
    viewBox="0 0 66 66"
  >
    <path
      fill={color}
      d="M46.75 16.5H60.5V22H55v35.75a2.7 2.7 0 0 1-.21 1.05c-.138.34-.337.638-.595.895a2.655 2.655 0 0 1-.894.594 2.7 2.7 0 0 1-1.051.211h-38.5a2.7 2.7 0 0 1-1.05-.21 2.655 2.655 0 0 1-.895-.595 2.655 2.655 0 0 1-.594-.894A2.7 2.7 0 0 1 11 57.75V22H5.5v-5.5h13.75V8.25c0-.363.07-.715.21-1.05.138-.34.337-.638.595-.895.258-.258.554-.457.894-.594A2.7 2.7 0 0 1 22 5.5h22a2.7 2.7 0 0 1 1.05.21c.34.138.638.337.895.595.258.258.457.554.594.894.14.336.211.688.211 1.051ZM49.5 22h-33v33h33ZM24.75 11v5.5h16.5V11Zm0 0"
    />
    <path
      fill="none"
      stroke={color}
      strokeWidth="15"
      d="M.006 7.496h87.497"
      transform="matrix(0 .27657 -.275 0 28.82 27.088)"
    />
    <path
      fill="none"
      stroke={color}
      strokeWidth="15"
      d="M.006 7.502h87.497"
      transform="matrix(0 .27657 -.275 0 41.497 27.088)"
    />
  </svg>
);
