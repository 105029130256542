import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono Share
 */
export const Share = ({
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
  ...props
}: IconType): JSX.Element => (
  <svg fill="none" viewBox="0 0 24 24" width={width} height={height} {...props}>
    <g clipPath="M.53.53h24v24h-24z">
      <path
        fill={color}
        d="m13.65 17.552-4.2-2.29a4 4 0 1 1 0-5.465l4.2-2.29a4 4 0 1 1 .96 1.755l-4.2 2.29a4.008 4.008 0 0 1 0 1.954l4.198 2.29a4 4 0 1 1-.959 1.755v.001ZM6.53 14.53a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm11-6a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 12a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      />
    </g>
  </svg>
);
