import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono Visa
 */
export const Visa = ({
  className,
  width = 28,
  height = 21,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 28 21"
  >
    <path
      d="M3 .208c-1.645 0-3 1.355-3 3v14c0 1.646 1.355 3 3 3h22c1.645 0 3-1.354 3-3v-14c0-1.645-1.355-3-3-3H3Zm0 2h22c.565 0 1 .435 1 1v14c0 .566-.435 1-1 1H3c-.565 0-1-.434-1-1v-14c0-.565.435-1 1-1Zm14.406 4.375c-1.926 0-2.937.95-2.937 2.156 0 2.183 2.56 1.88 2.56 3 0 .193-.16.627-1.22.627s-1.75-.374-1.75-.374l-.31 1.44s.655.404 1.97.404c1.31 0 3.154-1.008 3.154-2.47 0-1.755-2.563-1.871-2.563-2.655 0-.4.34-.72 1.25-.72.594 0 1.25.47 1.25.47l.314-1.53s-.87-.345-1.72-.345l.002-.003Zm-8.218.125L7.5 11.646s-.084-.428-.125-.688c-.955-2.14-2.5-2.938-2.5-2.938l1.47 5.595h2l2.81-6.907H9.188Zm2.687 0-1.094 6.906h1.846l1.093-6.906h-1.845Zm9.938 0-3 6.906h1.812l.375-.968h2.313l.187.968h1.656L23.75 6.708h-1.938.001Zm-18.97.03s3.597 1.105 4.438 3.814l-.623-3.124s-.275-.69-1-.69H2.843ZM22.5 8.709l.53 2.595h-1.5l.97-2.595Z"
      fill={color}
    />
  </svg>
);
