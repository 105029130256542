import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono corazón
 */
export const HeartFill = ({
  className,
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    viewBox="0 0 20 19"
    fill="none"
  >
    <path
      d="M14.5 0C17.538 0 20 2.5 20 6C20 13 12.5 17 10 18.5C7.5 17 0 13 0 6C0 2.5 2.5 0 5.5 0C7.36 0 9 1 10 2C11 1 12.64 0 14.5 0Z"
      fill={color}
    />
  </svg>
);
