import { gql, useQuery } from '@apollo/client';

const EMPLOYEE_SNACKBAR_QUERY = gql`
  query employeeSnackbar {
    bcGeneralSettings {
      ajustesGenerales {
        employeeSnackbarActive
        employeeSnackbar {
          message
          customMobileMessage
          mobileMessage
          coupon: freeOrderCoupon
          extraCoupon
          backgroundColor
          textColor
        }
      }
    }
  }
`;

type EmployeeSnackbarQuery = {
  bcGeneralSettings: {
    ajustesGenerales: {
      employeeSnackbarActive: boolean;
      employeeSnackbar: {
        message: string;
        coupon: string;
        extraCoupon?: string;
        backgroundColor: string;
        textColor: string;
      } & (
        | {
            customMobileMessage: true;
            mobileMessage: string;
          }
        | {
            customMobileMessage: null;
          }
      );
    };
  };
};

const COUPON_USES_LEFT_QUERY = gql`
  query getUsesLeftCoupon($userId: ID!, $coupon: String) {
    user(id: $userId, idType: DATABASE_ID) {
      usesLeftCoupon(couponCode: $coupon)
    }
  }
`;

type CouponUsesLeftQuery = {
  user: { usesLeftCoupon: number };
};

export const useEmployeeSnackbar = ({ id }: { id: string }) => {
  const {
    loading: snackbarLoading,
    error: snackbarError,
    data: snackbarData,
  } = useQuery<EmployeeSnackbarQuery>(EMPLOYEE_SNACKBAR_QUERY, {
    errorPolicy: 'all',
  });

  const {
    loading: couponUsesLeftLoading,
    error: couponUsesLeftError,
    data: couponUsesLeftData,
  } = useQuery<CouponUsesLeftQuery>(COUPON_USES_LEFT_QUERY, {
    variables: {
      userId: id,
      coupon:
        snackbarData?.bcGeneralSettings.ajustesGenerales.employeeSnackbar
          .coupon,
    },
    skip:
      !snackbarData ||
      !snackbarData.bcGeneralSettings.ajustesGenerales.employeeSnackbarActive,
    errorPolicy: 'all',
  });

  return {
    snackbarLoading,
    snackbarError,
    snackbarData,
    couponUsesLeftLoading,
    couponUsesLeftError,
    couponUsesLeftData,
  };
};
