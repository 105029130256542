import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono Bizum
 */
export const Reload = ({
  className,
  width = 12,
  height = 12,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    width={width}
    height={height}
    {...(className && { className })}
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M2.18677 1.58594C3.24526 0.668745 4.59943 0.164739 6.00002 0.166693C9.22177 0.166693 11.8334 2.77828 11.8334 6.00003C11.8334 7.24603 11.4425 8.40103 10.7775 9.34836L8.91669 6.00003H10.6667C10.6668 5.08514 10.3979 4.19041 9.89357 3.42709C9.38923 2.66377 8.67165 2.06554 7.83004 1.70676C6.98844 1.34799 6.05994 1.24452 5.16 1.4092C4.26005 1.57389 3.42837 1.99947 2.76835 2.63303L2.18677 1.58594ZM9.81327 10.4141C8.75478 11.3313 7.40061 11.8353 6.00002 11.8334C2.77827 11.8334 0.166687 9.22178 0.166687 6.00003C0.166687 4.75403 0.55752 3.59903 1.22252 2.65169L3.08335 6.00003H1.33335C1.33328 6.91491 1.60213 7.80964 2.10647 8.57296C2.61081 9.33628 3.32839 9.93452 4.17 10.2933C5.01161 10.6521 5.9401 10.7555 6.84005 10.5908C7.73999 10.4262 8.57167 10.0006 9.23169 9.36703L9.81327 10.4141Z"
      fill={color}
    />
  </svg>
);
