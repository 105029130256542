import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono Ticket
 */
export const Ticket = ({
  className,
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      d="M2 9.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v5.5a2.5 2.5 0 0 0 0 5V20a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-5.5a2.5 2.5 0 0 0 0-5ZM14 5H4v2.968a4.5 4.5 0 0 1 0 8.064V19h10V5Zm2 0v14h4v-2.968a4.5 4.5 0 0 1 0-8.064V5h-4Z"
      fill={color}
    />
  </svg>
);
