import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono Compass
 */
export const Compass = ({
  className,
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10Zm0-2a8 8 0 1 0 0-16.001A8 8 0 0 0 12 20Zm4.5-12.5L14 14l-6.5 2.5L10 10l6.5-2.5ZM12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      fill={color}
    />
  </svg>
);
