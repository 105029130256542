import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono ChevronLeft
 */
export const ChevronLeft = ({
  className,
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      d="m10.828 12 4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414-4.95 4.95Z"
      fill={color}
    />
  </svg>
);
