import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono Cart
 */
export const Cart = ({
  className,
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      d="M12 2a6 6 0 0 1 6 6v1h4v2h-1.167l-.757 9.083a1 1 0 0 1-.996.917H4.92a1 1 0 0 1-.996-.917L3.166 11H2V9h4V8a6 6 0 0 1 6-6Zm6.826 9H5.173l.667 8h12.319l.667-8ZM13 13v4h-2v-4h2Zm-4 0v4H7v-4h2Zm8 0v4h-2v-4h2Zm-5-9a4 4 0 0 0-3.995 3.8L8 8v1h8V8a4 4 0 0 0-3.8-3.995L12 4Z"
      fill={color}
    />
  </svg>
);
