import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono Copy
 */
export const Copy = ({
  className,
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 14 14"
  >
    <path
      d="M4.083 4.083V1.75a.583.583 0 0 1 .584-.583h7.583a.583.583 0 0 1 .583.583v7.583a.583.583 0 0 1-.583.583H9.917v2.33a.587.587 0 0 1-.588.587H1.754a.588.588 0 0 1-.587-.587l.001-7.575c0-.325.262-.588.588-.588h2.327Zm1.167 0h4.08c.324 0 .587.262.587.588V8.75h1.75V2.333H5.25v1.75ZM2.335 5.25l-.002 6.416H8.75V5.25H2.335Z"
      fill={color}
    />
  </svg>
);
