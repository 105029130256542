import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono PayPalLogo
 */
export const PayPalLogo = ({
  className,
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      d="M8.495 20.667h1.551l.538-3.376a2.806 2.806 0 0 1 2.77-2.366h.5c2.677 0 4.06-.983 4.55-3.503.208-1.066.117-1.73-.17-2.102-1.208 3.054-3.79 4.16-6.963 4.16h-.884c-.384 0-.794.209-.852.58l-1.04 6.607Zm-4.944-.294a.55.55 0 0 1-.544-.637L5.68 2.776A.92.92 0 0 1 6.59 2h6.424c2.212 0 3.942.467 4.9 1.558.87.99 1.122 2.084.87 3.692.36.191.668.425.916.706.818.933.978 2.26.668 3.85-.74 3.805-3.276 5.12-6.514 5.12h-.5a.806.806 0 0 0-.794.679l-.702 4.383a.803.803 0 0 1-.794.679H6.72a.483.483 0 0 1-.477-.558l.274-1.736H3.55h.001Zm6.836-8.894h.884c3.19 0 4.895-1.212 5.483-4.229.02-.101.037-.203.053-.309.166-1.06.05-1.553-.398-2.063-.465-.53-1.603-.878-3.396-.878h-5.5L5.246 18.373h1.561l.73-4.628.007.001a2.915 2.915 0 0 1 2.843-2.267Z"
      fill={color}
    />
  </svg>
);
