import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono Edit
 */
export const Edit = ({
  className,
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      d="M3.333 12.667h.943l6.21-6.21-.943-.942-6.21 6.21v.942ZM14 14H2v-2.829l8.957-8.956a.667.667 0 0 1 .942 0L13.785 4.1a.667.667 0 0 1 0 .942l-7.623 7.624H14V14Zm-3.515-9.428.943.943.943-.943-.943-.943-.943.943Z"
      fill={color}
    />
  </svg>
);
