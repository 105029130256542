import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Gift
 */
export const Gift = ({
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
  ...props
}: IconType): JSX.Element => (
  <svg viewBox="0 0 30 30" fill="none" width={width} height={height} {...props}>
    <g d="M3 4h24v24H3z">
      <path
        fill={color}
        d="M18 6a4 4 0 0 1 3.464 6.001L26 12v2h-2v10a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V14H4v-2l4.536.001A4 4 0 0 1 15 7.355 3.983 3.983 0 0 1 18 6Zm-4 8H8v9h6v-9Zm8 0h-6v9h6v-9ZM12 8a2 2 0 0 0-.15 3.995L12 12h2v-2a2 2 0 0 0-1.697-1.977l-.154-.018L12 8Zm6 0a2 2 0 0 0-1.995 1.85L16 10v2h2a2 2 0 0 0 1.995-1.85L20 10a2 2 0 0 0-2-2Z"
      />
    </g>
  </svg>
);
