import type { IconType } from './types';

/**
 * EstrellaGalicia
 */
export const EstrellaGalicia = ({ width = 13, ...props }: IconType) => (
  <svg width={width} viewBox="0 0 13 13" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.411 6.33903C11.411 9.10348 9.17011 11.3371 6.40948 11.3371C3.64885 11.3371 1.40796 9.09923 1.40796 6.33903C1.40796 3.57883 3.64885 1.34094 6.40948 1.34094C9.17011 1.34094 11.411 3.57883 11.411 6.33903Z"
      fill="#F81616"
    />
    <path
      d="M10.9211 6.39419C10.9211 8.8444 8.91874 10.836 6.43928 10.836C3.95983 10.836 1.95752 8.84865 1.95752 6.39419C1.95752 3.93974 3.95983 1.95239 6.43928 1.95239C8.91874 1.95239 10.9211 3.93974 10.9211 6.39419Z"
      fill="#F81616"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.84741 8.39011L8.72698 6.45797L9.86872 4.51734L7.61505 4.50884L6.48609 2.56396L5.34435 4.50035H3.08643L4.21538 6.44948L3.08643 8.38162H5.3486L6.4648 10.318L7.59376 8.38162L9.84741 8.39011Z"
      fill="white"
    />
    <path
      d="M6.43922 12.2459C3.2057 12.2459 0.577148 9.61732 0.577148 6.38151C0.577148 3.14571 3.2057 0.517151 6.43922 0.517151C9.67274 0.517151 12.3013 3.14571 12.3013 6.38151C12.3013 9.61732 9.67274 12.2459 6.43922 12.2459ZM6.43922 1.91848C3.98107 1.91848 1.97876 3.91857 1.97876 6.38151C1.97876 8.84446 3.97681 10.8445 6.43922 10.8445C8.90164 10.8445 10.8997 8.84446 10.8997 6.38151C10.8997 3.91857 8.90164 1.91848 6.43922 1.91848Z"
      fill="#262626"
    />
  </svg>
);
