import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono YouTube
 */
export const YouTube = ({
  className,
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 25"
  >
    <path
      d="M19.606 7.412c-.076-.298-.292-.523-.539-.592-.437-.123-2.567-.403-7.067-.403s-6.628.28-7.069.403c-.244.068-.46.293-.537.592C4.285 7.836 4 9.613 4 12.417s.285 4.58.394 5.006c.076.297.292.522.538.59.44.124 2.568.404 7.068.404s6.629-.28 7.069-.403c.244-.068.46-.293.537-.592.109-.424.394-2.205.394-5.005s-.285-4.58-.394-5.005Zm1.937-.497C22 8.697 22 12.417 22 12.417s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022-1.709.476-7.605.476-7.605.476s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 16.137 2 12.417 2 12.417s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4.417 12 4.417 12 4.417s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022ZM10 15.917v-7l6 3.5-6 3.5Z"
      fill={color}
    />
  </svg>
);
